/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import hasPermission from '@/utilities/permission.js';

// import pages
const Home = () => import('@/pages/Home.vue');
const HomeEcommerce = () => import('@/pages/HomeEcommerce.vue');
const Dashboard = () => import('@/pages/dashboards/Dashboard.vue');
const HomeMarketing = () => import('@/pages/HomeMarketing.vue');
const HomeNft = () => import('@/pages/HomeNft.vue');

const NotFound = () => import('@/pages/error/NotFound.vue');

const ProjectsPage = () => import('@/pages/projects/ProjectList.vue');
const ProjectTemplatesPage = () => import('@/pages/projects/ProjectTemplatesList.vue');
const ProjectsEditPage = () => import('@/pages/projects/ProjectEdit.vue');
const CustomersPage = () => import('@/pages/customers/CustomerList.vue');
const CustomersEditPage = () => import('@/pages/customers/CustomerEdit.vue');
const TagPage = () => import('@/pages/tags/TagList.vue');
const TagEditPage = () => import('@/pages/tags/TagEdit.vue');
const AuditPage = () => import('@/pages/audit/Audit.vue');
const SettingsPage = () => import('@/pages/settings/Settings.vue');
const SubscriptionPage = () => import('@/pages/subscription/Subscription.vue');
const IntegrationsPage = () => import('@/pages/settings/Integrations.vue');
const TimeTrackerPage = () => import('@/pages/time-management/TimeTracker.vue');
const TimeTrackingPage = () => import('@/pages/time-management/TimeTracking.vue');
const TimesheetPage = () => import('@/pages/time-management/Timesheet.vue');
const ApprovalsPage = () => import('@/pages/time-management/Approvals.vue');
const ApprovalsTimesheetPage = () => import('@/pages/time-management/ApprovalsTimesheet.vue');
const TimeCalendarPage = () => import('@/pages/time-management/TimeCalendar.vue');
const DetailedReportPage = () => import('@/pages/reports/DetailedReport.vue');
const SummaryReportPage = () => import('@/pages/reports/SummaryReport.vue');
const ProductivityReportPage = () => import('@/pages/reports/ProductivityReport.vue');
const ClientSummaryReportPage = () => import('@/pages/reports/ClientSummaryReport.vue');
const ProjectSummaryReportPage = () => import('@/pages/reports/ProjectSummaryReport.vue');
const OutstandingTimeReportPage = () => import('@/pages/reports/OutstandingTimeReport.vue');
const ProjectManagementPage = () => import('@/pages/work-management/ProjectManagement.vue');

const UserList = () => import('@/pages/user-manage/UserList');
const UserCards = () => import('@/pages/user-manage/UserCards');
const UserEdit = () => import('@/pages/user-manage/UserEdit');
const GroupEdit = () => import('@/pages/user-manage/GroupEdit');

//Profile pages
const Profile = () => import('@/pages/Profile');
const MyContacts = () => import('@/pages/MyContacts');
const ProfileSettings = () => import('@/pages/ProfileSettings');

// ui components
const Accordion = () => import('@/pages/components/Accordion');
const Alert = () => import('@/pages/components/Alert');
const Badge = () => import('@/pages/components/Badge');
const Breadcrumb = () => import('@/pages/components/Breadcrumb');
const Buttons = () => import('@/pages/components/Buttons');
const ButtonGroup = () => import('@/pages/components/ButtonGroup');
const Cards = () => import('@/pages/components/Cards');
const Carousel = () => import('@/pages/components/Carousel');
const CloseButton = () => import('@/pages/components/CloseButton');
const Collapse = () => import('@/pages/components/Collapse');
const Dropdown = () => import('@/pages/components/Dropdown');
const ListGroup = () => import('@/pages/components/ListGroup');
const Modal = () => import('@/pages/components/Modal');
const Tabs = () => import('@/pages/components/Tabs');
const Offcanvas = () => import('@/pages/components/Offcanvas');
const Pagination = () => import('@/pages/components/Pagination');
const Placeholders = () => import('@/pages/components/Placeholders');
const Popovers = () => import('@/pages/components/Popovers');
const Progress = () => import('@/pages/components/Progress');
const Spinners = () => import('@/pages/components/Spinners');
const Toasts = () => import('@/pages/components/Toasts');
const Tooltips = () => import('@/pages/components/Tooltips');

// utilities
const Misc = () => import('@/pages/utilities/Misc');
const Typography = () => import('@/pages/utilities/Typography');
const Image = () => import('@/pages/utilities/Image');
const Tables = () => import('@/pages/utilities/Tables');
const Background = () => import('@/pages/utilities/Background');
const Borders = () => import('@/pages/utilities/Borders');
const Colors = () => import('@/pages/utilities/Colors');
const Flex = () => import('@/pages/utilities/Flex');
const Sizing = () => import('@/pages/utilities/Sizing');
const Spacing = () => import('@/pages/utilities/Spacing');

// forms
const FormControl = () => import('@/pages/forms/FormControl');
const Select = () => import('@/pages/forms/Select');
const ChecksRadios = () => import('@/pages/forms/ChecksRadios');
const Range = () => import('@/pages/forms/Range');
const InputGroup = () => import('@/pages/forms/InputGroup');
const FloatingLabels = () => import('@/pages/forms/FloatingLabels');
const Layout = () => import('@/pages/forms/Layout');
const Validation = () => import('@/pages/forms/Validation');
const DateTime = () => import('@/pages/forms/DateTime');
const FormUpload = () => import('@/pages/forms/FormUpload');
const Quill = () => import('@/pages/forms/editors/Quill');
const Tinymce = () => import('@/pages/forms/editors/Tinymce');

// layout
const Breakpoints = () => import('@/pages/layout/Breakpoints');
const Containers = () => import('@/pages/layout/Containers');
const Gutters = () => import('@/pages/layout/Gutters');

//const pages
const DataTables = () => import('@/pages/DataTables');
const Charts = () => import('@/pages/Charts');
const Sweetalert = () => import('@/pages/Sweetalert');

// apps apages
const Calendar = () => import('@/pages/apps/fullcalendar/Calendar');
const KanbanBoard = () => import('@/pages/apps/kanban/KanbanBoard');
const KanbanBoardCustom = () => import('@/pages/apps/kanban/KanbanBoardCustom');
const Chats = () => import('@/pages/apps/chat/Chats');
const Inbox = () => import('@/pages/apps/mailbox/Inbox');

// ecommerce pages
const Products = () => import('@/pages/ecommerce/Products');
const Categories = () => import('@/pages/ecommerce/Categories');
const AddProduct = () => import('@/pages/ecommerce/AddProduct');
const EditProduct = () => import('@/pages/ecommerce/EditProduct');
const AddCategory = () => import('@/pages/ecommerce/AddCategory');
const EditCategory = () => import('@/pages/ecommerce/EditCategory');

// auths pages
const AuthRegister = () => import('@/pages/auths/AuthRegister');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const AuthReset = () => import('@/pages/auths/AuthReset');
const PassReset = () => import('@/pages/auths/PassReset');


const Icons = () => import('@/pages/Icons');


const routes = [
  // PUBLIC ROUTES
  {
    path: '/register',
    name: 'Register',
    component: AuthRegister
  },
  {
    path: '/login',
    name: 'Login',
    component: AuthLogin
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: PassReset
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: AuthReset
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta:{
      action: 'view',
      section: 'time',
    },
  },
  {
    path: '/',
    name: 'Home',
    component: TimeTrackingPage,
    alias: '/home',
    meta:{
      action: 'view',
      section: 'time',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: UserList,
    meta:{
      action: 'view',
      section: 'user',
    },
  },
  /*{
    path: '/user-manage/user-Cards',
    name: 'Users Cards',
    component: UserCards,
    meta:{
      action: 'view',
      section: 'user',
    },
  },*/
  {
    path: '/users/:id',
    name: 'Edit User',
    component: UserEdit,
    meta:{
      action: 'anyEdit',
      section: 'user',
    },
  },
  {
    path: '/user-groups/:id',
    name: 'Edit User Group',
    component: GroupEdit,
    meta:{
      action: 'anyEdit',
      section: 'user',
    },
  },
  {
    path: '/profile',
    name: 'My Profile',
    component: Profile,
    meta:{
      action: '',
      section: '',
    },
  },
  /*{
    path: '/my-contacts',
    name: 'My Contact',
    component: MyContacts,
    meta:{
      action: '',
      section: '',
    },
  },*/
  {
    path: '/profile-settings',
    name: 'Edit Settings',
    component: ProfileSettings,
    meta:{
      action: '',
      section: '',
    },
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
  {
    path: '/projects/templates',
    name: 'Project Templates',
    component: ProjectTemplatesPage,
    meta:{
      action: 'view',
      section: 'project',
    },
  },
  {
    path: '/projects/:id',
    name: 'Edit Project',
    component: ProjectsEditPage,
    meta:{
      action: 'anyEdit',
      section: 'project',
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: ProjectsPage,
    meta:{
      action: 'view',
      section: 'project',
    },
  },
  {
    path: '/customers/:id',
    name: 'Customer',
    component: CustomersEditPage,
    meta:{
      action: 'anyEdit',
      section: 'customer',
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersPage,
    meta:{
      action: 'view',
      section: 'customer',
    },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: TagPage,
    meta:{
      action: 'view',
      section: 'data',
    },
  },
  {
    path: '/tags/:id',
    name: 'Edit Tag',
    component: TagEditPage,
    meta:{
      action: 'anyEdit',
      section: 'data',
    },
  },
  /*{
    path: '/audit',
    name: 'Audit',
    component: AuditPage
  },*/
  {
    path: '/integrations',
    name: 'Integrations',
    component: IntegrationsPage,
    meta:{
      action: 'isAdmin',
      section: '',
    },
  },
  {
    path: '/subscription',
    name: 'Subscription',
    component: SubscriptionPage,
    meta:{
      action: 'isAdmin',
      section: '',
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsPage,
    meta:{
      action: 'isAdmin',
      section: '',
    },
  },
  {
    path: '/project-management',
    name: 'Project Management',
    component: ProjectManagementPage,
    meta:{
      action: 'view',
      section: 'data',
    }
  },
  {
    path: '/time-tracking',
    name: 'Time Tracking',
    component: TimeTrackingPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/time-tracker',
    name: 'Time Tracker',
    component: TimeTrackerPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: TimesheetPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/approvals',
    name: 'Approvals',
    component: ApprovalsPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/approvals/:id',
    name: 'Approvals Timesheet',
    component: ApprovalsTimesheetPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: TimeCalendarPage,
    meta:{
      action: 'view',
      section: 'time',
    }
  },
  {
    path: '/reports/detailed',
    name: 'Detailed Report',
    component: DetailedReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    }
  },
  {
    path: '/reports/summary',
    name: 'Summary Report',
    component: SummaryReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    }
  },
  /*{
    path: '/reports/client-summary',
    name: 'Client Summary Report',
    component: ClientSummaryReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    },
  },*/
  {
    path: '/reports/project-summary',
    name: 'Project Summary Report',
    component: ProjectSummaryReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    }
  },
  {
    path: '/reports/productivity',
    name: 'Productivity Report',
    component: ProductivityReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    },
  },
  {
    path: '/reports/outstanding-time',
    name: 'Outstanding Time Report',
    component: OutstandingTimeReportPage,
    meta:{
      action: 'view',
      section: 'reports',
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ${process.env.VUE_APP_TITLE}`
  const publicRoutes = ['/register', '/login','/forgot-password', '/reset-password'];
  const isAuthenticated = localStorage.getItem('nioBoardAuthenticated')

  // If its a public route (don't need to be logged in) - then go for it
  if(publicRoutes.includes(to.path)) {
    next();
  }
  // If we are not a public route - then we need to be logged in
  else if(!publicRoutes.includes(to.path) && isAuthenticated){
    
    // If the page we are trying to reach does not have any permissions configured yet - don't let them move there
    //  TODO: User Feedback
    if(to.meta === undefined || to.meta?.action === undefined || to.meta?.section === undefined){
      console.log(to.fullPath + " Page does not have permissions configured!");
      next(from.path);
    }

    // We just need to be logged in
    if(to.meta?.action == '' && to.meta?.section == ''){
      next();
    }

    // Check if the user has permission to access the page - if not then don't let them move there
    //  TODO: User Feedback
    if(!hasPermission(to.meta?.action, to.meta?.section)){
      console.log("You do not have permissions for the " + to.path + " page");
      next(from.path);
    }

    // If we are here then the page has permissions configured and the user has permission to the page - redirect
    next();

  // If we are not a public route and we are not logged in - login page
  } else{
    next('/login');
  }
})

export default router
